import React from 'react'

import Modal from 'src/components/modal/index'

import styles from './modal.module.scss'


const AdmissionModal = ({ isOpen, close }) => {

    return (
        <Modal isOpen={isOpen}>
            <div className={styles.modal}>
                <button onClick={close} aria-label="close" className={styles.back}></button>

                <div className={styles.content}>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video src="/img/top/concept_movie.mp4" controls autoPlay playsInline></video>
                </div>

                <button onClick={close} aria-label="close" className={styles.close}></button>
            </div>
        </Modal>
    )
}

export default AdmissionModal