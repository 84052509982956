import React, { useEffect, useRef } from 'react'

import Polaris from 'src/lib/Polaris'

import SEO from 'src/components/seo/index'
import Header from 'src/components/header/setting'
import Mainview from 'src/components/mainview/index'
import Title from 'src/components/title/index'
import Inner from 'src/components/inner/index'
import Image from 'src/components/image/index'
import Link from 'src/components/link/index'
import MovieButton from 'src/components/movie/button'
import AdmissionButton from 'src/components/admission/button'
import Information from 'src/components/information/index'
import Features from 'src/components/features/index'
import Slider from 'src/components/news/slider'
import I_AM_THE_FIT from 'src/components/iatf/index'
import BodyStyling from 'src/components/bodystyling/index'
import Prices from 'src/components/prices/index'
import ReadyToGo from 'src/components/readytogo/index'

import styles from './index.module.scss'

const IndexPage = () => {
	const wrapper = useRef()
	const video = useRef()

	useEffect(() => {
		const resizeID = Polaris.util.onResize(() => {
			const w = wrapper.current.clientWidth
			const h = wrapper.current.clientHeight
			let vw = w
			let vh = (w * 9) / 16

			if (vh < h) {
				vh = h
				vw = (h / 9) * 16
			}
			video.current.style.width = vw + 'px'
			// video.current.style.height = vh + 'px'
			// video.current.style.top = (h / 2 - vh / 2) + 'px'
			// video.current.style.left = (w / 2 - vw / 2) + 'px'
		})

		return () => {
			Polaris.util.offResize(resizeID)
		}
	}, [])

	return (
		<div className={styles.top}>
			<SEO title="" />
			<Header hasBack={false} />

			<Mainview className={styles.mainView}>
				<div className={styles.mainBody}>
					<div ref={wrapper} className={styles.mainVideo}>
						<div ref={video} className={styles.mainVideoContainer}>
							<div className={styles.mainVideoPlacer}>
								<video src="/img/top/mainmovie.mp4" muted loop autoPlay playsInline />
							</div>
						</div>
					</div>
					<div className={styles.mainText}>
						<div>
							<Link to="/gyms/toritsudai/">
								<span className={styles.weare}>WE ARE THE FIT</span>
								<span className={styles.name}>TORITSUDAI</span>
								<span className={styles.goto}>GO TO TORITSUDAI</span>
							</Link>
						</div>
						<div>
							<Link to="/gyms/ekoda/">
								<span className={styles.weare}>WE ARE THE FIT</span>
								<span className={styles.name}>EKODA</span>
								<span className={styles.goto}>GO TO EKODA</span>
							</Link>
						</div>
					</div>
				</div>
				<Information />
			</Mainview>

			<section id="news">
				<Title
					h2="NEWS"
					right={
						<Link className={styles.newsLink} to="/news/">
							View All News
						</Link>
					}
				/>
				<div className={styles.newsList}>
					<Slider filter="latest" />
				</div>
				<div className={styles.newsAll}>
					<Link to="/news/">View All News</Link>
				</div>
			</section>

			<section id="about">
				<div className={styles.aboutMovie}>
					<div className={styles.aboutBackground}>
						<Image src="/top/about.jpg" alt="" minWidth={880} />
					</div>
					<div className={styles.aboutTitle}>
						<div>
							<h2>ABOUT</h2>
						</div>
					</div>
					<MovieButton className={styles.aboutMovieButton}>
						<span>play</span>
					</MovieButton>
				</div>
				<div className={styles.aboutContent}>
					<div>
						<div className={styles.aboutLeft}>
							<div className={styles.aboutLogo}>
								<div className={styles.placer}>
									<img src="/img/top/logo.svg" alt="WE ARE THE FIT" />
								</div>
							</div>
							<div className={styles.aboutButtons}>
								<AdmissionButton className={styles.admission} />
								<Link className={styles.beginners} to="/beginners/">
									ビギナーの方へ
								</Link>
							</div>
						</div>
						<div className={styles.aboutRight}>
							<div className={styles.aboutText1}>
								<p>
									本気の競技者達が引き寄せられる
									<br />
									本格・本物のトレーニングができる場。
									<br />
									ここに来ればいつものメンバーに会える。
									<br />
									お互いを高め合い切磋琢磨し、
									<br />
									自分を超えようと頑張る仲間がいる。
								</p>
								<p>
									今は大変な時代だからこそ、
									<br />
									みんなで心のディスタンスを近づけよう。
									<br />
									アナタは1人じゃない。
									<br />
									カラダを鍛え、ココロを磨くよろこびを
									<br />
									みんなで分かちあおう。
								</p>
								<p>
									私たちの 私たちによる 私たちがつくり上げる
									<br />
									唯一無二の場所、
									<br />
									それこそが<span>WE ARE THE FIT</span>。
								</p>
							</div>
							<div className={styles.aboutText2}>
								<p>さあ、一緒に。</p>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.aboutFeatures}>
					<Features
						items={[
							{
								title: `レベルにあわせたワークアウトフロア`,
								text: `フィットネス初心者でも、ご安心ください。<br />WE ARE THE FITは初心者向けフロアをご用意しておりますので、これからフィットネスを初めてみたい方でも周りに気兼ねなく安心してご利用いただけます。<br />ご希望の方には、初回オリエンテーションも実施しております。スタッフが施設案内・マシーンの使い方の説明・カウンセリングを行いますので初めての方でもご心配なく。`,
								image: `/top/feature_01.jpg`,
							},
							{
								title: `24時間利用可能`,
								text: `WE ARE THE FIT は24時間営業のフィットネスクラブです。<br />スタッフの常駐していない早朝・深夜帯でも、セキュリティカードをかざすだけでいつでも施設を利用できます。しっかりセキュリティ対策もされているので女性の方の深夜帯利用も安心です。`,
								image: `/top/feature_02.jpg`,
							},
							{
								title: `本格マシーン多数設置`,
								text: `世界標準の最新マシーンを多数取り揃えました。かなり自信があります。特にフリーウェイトマシンの種類の豊富さには自信があります。ダンベルも最高50kgまでご用意。トレーニングに慣れている方、初級フロアでは物足りなくなった方、本格的なマシーンを使ったトレーニングで理想のカラダ作りを。`,
								image: `/top/feature_03.jpg`,
							},
							{
								title: `トレーニングのサポート`,
								text: `トレーニングのアドバイスを行うアドバイザースタッフが常駐しております（スタッフ在中時間帯）。鍛えたい部位にあわせたマシンを紹介したり、理想の体づくりにあわせたトレーニングアドバイスなどご質問にあわせてサポートいたしますのでお気軽にお声がけください。<br />また、より本格的なトレーニング指導・アドバイスを受けたい方は有料になりますが、パーソナルトレーニングもご用意しております。`,
								image: `/top/feature_04.jpg`,
							},
							{
								title: `低価格`,
								text: `WE ARE THE FITにはスタジオプログラムやレッスンプログラムはありません。その分、本格マシーンを低価格でご提供しております。１人１人の生活スタイルにあわせたワークアウトライフを無理なく長く続けていただければと思っています。`,
								image: `/top/feature_05.jpg`,
							},
						]}
					/>
				</div>
			</section>

			<section id="gyms" className={styles.gyms}>
				<Title h2="GYMS" />
				<Inner>
					<ul className={styles.gymsList}>
						<li>
							<Link to="/gyms/toritsudai/">
								<div className={styles.gymsPlacer}>
									<Image src="/top/gym_toritsudai.jpg" minWidth={0} />
								</div>
								<div className={styles.gymsText}>
									<h3>WE ARE THE FIT TORITSUDAI</h3>
									<button>詳細を見る</button>
								</div>
							</Link>
						</li>
						<li>
							<Link to="/gyms/ekoda/">
								<div className={styles.gymsPlacer}>
									<Image src="/top/gym_ekoda.jpg" minWidth={0} />
								</div>
								<div className={styles.gymsText}>
									<h3>WE ARE THE FIT EKODA</h3>
									<button>詳細を見る</button>
								</div>
							</Link>
						</li>
					</ul>
				</Inner>
			</section>

			<section id="services" className={styles.services}>
				<Title h2="SERVICES" />
				<Inner>
					<I_AM_THE_FIT />
					<BodyStyling />
				</Inner>
			</section>

			<section id="price" className={styles.price}>
				<div>
					<Title h2="PRICE" />
					<Inner>
						<Prices />
					</Inner>
				</div>
			</section>

			<ReadyToGo />
		</div>
	)
}

export default IndexPage
